code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  margin: 0;
  padding: 0;
  height: 100vh;
  background-color: white;
  color: #111111;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Arial, Helvetica, sans-serif;
  position: relative;
}
a{
  color: #a5569f;
}
.logo {
  width: 400px;
  height: auto;
  display: block;
  margin: 0 auto;
  position: relative;
  z-index:5;
}
header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  margin-bottom: -240px;
  position: relative;
}

nav ul {
  list-style-type: none;
  padding: 0;
  position: absolute;
  top: 180px;
  right: 50px;
  z-index: 10;
}

nav ul li {
  display: inline-block;
  margin: 0 10px;
}

nav ul li a {
  text-decoration: none;
  color: #5b2c57;
  font-weight: bold;
}
nav ul li a:hover, nav ul li a:focus {
  border-bottom: 1px solid #5b2c57;
}
.below-header {
  background-color: #eff2c3;
  width: 100%;
  position: relative;
  overflow: hidden;
  min-height: 480px;
  padding-top: 150px;
  top: -150px;
}

.below-header::after
 {
  content: '';
    position: absolute;
    bottom: -110px;
    left: -20%;
    right: 0;
    height: 50%;
    background: white; /* Light yellow background */
    border-radius: 100% 100% 0 0;
    transform: translateY(50%);
    width:140%;
}
.below-header::before {
  content: '';
  position: absolute;
  top: -50px;
  left: -30%;
  right: 0;
  height: 50%;
  background: white;
  border-radius: 0 0 100% 100%;
  transform: translateY(-50%);
  width:160%;
}
.content{
  margin: 0 auto;
  width: 80%;
  text-align: center;
  padding: 30px;
}
.main-content{
  margin: -150px 0 0 0;
  background-color: #ffffff;
  position: relative;
  z-index: 11;
}

h1, h2, h3{
  margin: 0;
  padding: 30px 0 20px 0;
}
h2 {
  font-size: 40px;
}
footer{
  width: 100%;
  background-color: #a5569f;
  min-height: 50px;
}
.subscribe-list {
  list-style-type: none;
  padding: 0;
}

.subscribe-list li {
  display: inline-block;
  margin: 0;
  padding:10px;
  position: relative;
}

.episode-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin: 20px 0;
}

.episode-item {
  background: #fff;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  text-align: center;
}

.episode-item img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  max-width: 400px;
}

.episode-item h3 {
  margin: 10px 0 0 0;
}

.episode-item h3 a{
  text-decoration: none;
}

.episode-item time {
  display: block;
  margin-bottom: 10px;
  color: #666;
  font-size: 12px;
}

button {
  color:#a5569f;
  border: 2px solid #a5569f;
  border-radius: 8px; /* Rounded corners */
  background-color: transparent; /* Transparent background */
  font-size: 16px; /* Font size */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s, color 0.3s; /* Smooth transition for background and text color */
  padding: 10px 20px;
}
button:hover {
  background-color: #a5569f; /* Purple background on hover */
  color: #fff; /* White text on hover */
}
button:focus {
  outline: none; /* Remove default focus outline */
  box-shadow: 0 0 5px rgba(165, 86, 159, 0.5); /* Add a subtle shadow on focus */
}

.about-page {
  width: 80%;
  margin: -100px auto 50px auto;
  padding: 20px;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  text-align: center;
}

.about-page h1 {
  margin-bottom: 20px;
}

.about-page p {
  margin-bottom: 10px;
}
.episode-page {
  width: 80%;
  margin: -100px auto 50px auto;
  padding: 20px;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  text-align: center;
}

.episode-page img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.episode-page h1 {
  margin-bottom: 20px;
}

.episode-page p {
  margin-bottom: 10px;
}

.embed-container {
  margin: 20px 0;
}

.embed-container iframe {
  width: 100%;
  height: 200px;
}

.transcript {
  margin-top: 30px;
  text-align: left;
}

.transcript h2 {
  margin-bottom: 10px;
}
input{
  border: 1px solid #ccc; 
  border-radius: 8px; 
  padding: 10px;
  font-size: 16px;
  width: 50%;
  box-sizing: border-box; 
}

input:focus {
  outline: none; /* Remove default focus outline */
  border-color: #888; /* Slightly darker grey border on focus */
}
.pagination button{
  margin: 0 5px;
}
.subscribe-page {
  width: 80%;
  margin: -100px auto 50px auto;
  padding: 20px;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  text-align: center;
}

.subscribe-page h1 {
  margin-bottom: 20px;
}

.subscribe-page p {
  margin-bottom: 20px;
}
.subscribe-options {
  list-style-type: none;
  padding: 0;
  display: flex;
  justify-content: center;
  gap: 20px;
}

.subscribe-options li {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
}

.subscribe-options li a {
  text-decoration: none;
  color: #111;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.subscribe-options li img {
  width: 50px;
  height: auto;
}

.subscribe-options li span {
  margin-top: 10px;
  font-size: 14px;
}

/* MEDIA Queries */

@media (max-width: 1200px) {
  header {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .logo {
    margin-bottom: 70px; /* Add some space below the logo */
  }

  nav ul {
    position: relative;
    right: 0;
    display: block;
  }
}

@media (max-width: 640px) {
  .episode-grid {
    display: block;
  }
}

@media (max-width: 540px) {
  .subscribe-list {
    display: none;
  }
}